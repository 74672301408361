import React from "react"
import FirstPage from "../components/StartScreen/FirstPage"
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet'

const IndexPage = ({data}) => (
    <div>
    <Helmet title="foo bar" defer={false}>
    <html lang='de' />
    <meta charSet="utf-8" />
    <title>MEDIENQUIZ</title>
    <meta name="description" content="MEDIENQUIZ" />
    <meta name='robots' content='index, follow' />
    <script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" 
    data-respect-dnt="true" data-secure-code="8bbt9s" src="//static.etracker.com/code/e.js"/>
</Helmet>
    <FirstPage
    data={data}
    />
</div>
)

export default IndexPage

export const query = graphql`
    query {
            strapiStartScreen {
                Headline,
                Title,
                Text,
                ButtonText,
                StartImage {
                    publicURL
                  }   
         
   } }`