import React from 'react'
import s from './FirstPage.module.css'
import LogoSchauHin from '../LogoSchauHin'
import { Link } from 'gatsby'


export default function (props) {


    return (
        <div className={s.wrapper}>
            <div className={s.fullscreen}>
                <LogoSchauHin />
                <p className={s.headline}>
                    {props.data.strapiStartScreen.Headline}
                </p>
                <p className={s.title}>
                    {props.data.strapiStartScreen.Title}
                </p>
                <p className={s.text}>
                    {props.data.strapiStartScreen.Text}
                </p>
                <Link className={s.mainButton} to='/Themen'>
                    <p className={s.mainButtonText}>
                        {props.data.strapiStartScreen.ButtonText}
                    </p>
                </Link>
                <img className={s.svgBlock} src={props.data.strapiStartScreen.StartImage.publicURL} alt='logo MEDIENQUIZ' />
            </div>
        </div>
    )
}


